import React from 'react'

import { CheckIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import { BILLING_CYCLE } from 'api/src/common/enums'
import { isNil } from 'ramda'
import { CurrentProduct, ProductPlan } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'

interface PlanProps {
  product: ProductPlan
  onClick?(): void
  currentProduct?: CurrentProduct
  newSale?: boolean
  index?: number
  billingPeriod?: BILLING_CYCLE
  isEnterprise?: boolean
}

const Product: React.FC<PlanProps> = ({
  product,
  onClick,
  currentProduct,
  newSale,
  index,
  billingPeriod = 'monthly',
  isEnterprise,
}) => {
  const productPrice =
    billingPeriod === 'monthly' ? product.defaultPrice : product.yearlyPrice
  const hasYearlyPrice = !!product.yearlyPrice

  if (!hasYearlyPrice && billingPeriod === 'yearly') {
    return null
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: productPrice.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const isCurrentProduct = currentProduct?.id === product.id
  const isLowerTier = product.metadata?.tier < currentProduct?.tier

  return (
    <div
      className={`${
        isCurrentProduct ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-400'
      } rounded-3xl p-6 w-[320px]`}
    >
      <Stack spacing={3}>
        <div className="flex flex-col justify-between">
          <div className={'flex flex-row items-center justify-between gap-1'}>
            <h3
              className={` ${
                isCurrentProduct ? 'text-indigo-600' : 'text-gray-900'
              } text-lg font-semibold leading-8`}
            >
              {product.name}
            </h3>
            {isCurrentProduct ? (
              <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-center text-indigo-600">
                Current Plan
              </p>
            ) : (
              index === 1 && (
                <p className="rounded-full bg-green-600/10 px-1.5 py-0.5 text-xs leading-5 text-center text-green-600">
                  Most Popular
                </p>
              )
            )}
          </div>
        </div>
        <div className="flex items-center">
          <span className="text-6xl font-w font-normal tracking-tight text-gray-900">
            {formatter.format(Math.round(productPrice.unitAmount / 100))}
          </span>
          {product.metadata?.isFixedPrice ? (
            <span className="text-md leading-4 text-gray-600 ml-4">
              Billed {billingPeriod} <br />
              <p className={'text-xs'}>excludes GST</p>
            </span>
          ) : (
            <span className="text-md leading-4 text-gray-600 ml-4">
              Per seat/{billingPeriod.slice(0, -2)} <br />
              Billed {billingPeriod} <br />
              <p className={'text-xs'}>excludes GST</p>
            </span>
          )}
        </div>
        {!isNil(onClick) && (
          <Button
            className="rounded-full"
            variant={'outlined'}
            disabled={isCurrentProduct || isEnterprise}
            onClick={() => {
              if (isLowerTier) {
                window.Intercom('showNewMessage')
              } else {
                onClick()
              }
            }}
          >
            {newSale
              ? 'Select Plan'
              : isCurrentProduct
                ? 'Current Plan'
                : isLowerTier
                  ? 'Contact Support'
                  : 'Change Plan'}
          </Button>
        )}
        {isLowerTier && (
          <p className={'text-gray-400 text-sm'}>
            *You must contact support to downgrade
          </p>
        )}
        <ul className="text-sm leading-6 text-gray-600">
          <li className="text-sm leading-6"> Includes:</li>
          {product.features?.map((feature) => (
            <li key={feature.name} className="flex gap-x-2 font-semibold">
              <CheckIcon
                className="h-6 w-5 flex-none text-indigo-600 stroke-2"
                aria-hidden="true"
              />
              {feature.name}
            </li>
          ))}
        </ul>
      </Stack>
    </div>
  )
}

export default Product
