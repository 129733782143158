import { FC } from 'react'

import { CheckIcon, SparklesIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'

import Button from 'src/components/Library/Button/Button'

interface EnterpriseProductProps {
  isEnterprise?: boolean
}

const EnterpriseProduct: FC<EnterpriseProductProps> = ({ isEnterprise }) => {
  const features = [
    'Custom Bespoke Features',
    'Priority Support',
    'Early Access Features',
  ]
  return (
    <div className={'bg-gray-800 rounded-3xl p-8 w-[320px]'}>
      <Stack spacing={3}>
        <div className="flex flex-col justify-between">
          <div className={'flex flex-row items-center justify-between gap-1'}>
            <h3 className={'text-white text-lg font-semibold leading-8'}>
              Enterprise
            </h3>
            {isEnterprise && (
              <p className="rounded-full bg-indigo-300 px-2.5 py-1 text-xs font-semibold leading-5 text-center text-indigo-600">
                Current Plan
              </p>
            )}
          </div>
          <p className="text-sm leading-5 text-gray-400 break-words min-h-16">
            For large teams and organizations looking to scale up their
            operations. Minimum 100 users.
          </p>
        </div>
        <div className="flex">
          <SparklesIcon className="w-[60px] h-[60px] text-gray-400" />
        </div>
        <Button
          className="rounded-full bg-white text-black"
          onClick={() => {
            window.Intercom('showNewMessage')
          }}
        >
          Contact Us
        </Button>
        <ul className="text-sm leading-6 text-gray-200">
          <li className="text-sm leading-6"> Includes:</li>
          {features?.map((feature) => (
            <li key={feature} className="flex gap-x-2 text-gray-400">
              <CheckIcon
                className="h-6 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
      </Stack>
    </div>
  )
}

export default EnterpriseProduct
